@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
    --color-bg-primary: #2d3748;
    --color-bg-secondary: #283141;
    --color-text-primary: #f7fafc;
    --color-text-secondary: #e2e8f0;
    --color-text-accent: #81e6d9;
}
body {
    font-family: 'Inter', sans-serif;
    background-color: #2d3748;
}

.h-gradient {
    background: rgba(40,49,65,0.5);
}

.welcome-gradient {
    background: rgb(20,136,204);
    background: linear-gradient(90deg, rgba(20,136,204,1) 0%, rgba(43,50,178,1) 100%);
}